<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Users</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3">
        <b-button size="sm" variant="primary" @click="showModal">
          Create
          <b-icon-plus style="font-size: 1.2em" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3">
        <b-form-input v-model="searchText" trim placeholder="search by email" />
      </b-col>
    </b-row>
    <div ref="table-container">
      <small class="text-muted"> Total users: {{ rows }} </small>
      <div v-if="isBusy" class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
      <b-table
        bordered
        small
        striped
        :items="sortedItems"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data"
          >{{ 1 + data.index + (currentPage - 1) * perPage }}
        </template>
        <template v-slot:cell(roles)="data">
          {{ data.value.join(', ') }}
        </template>
        <template v-slot:cell(id)="data">
          <b-icon-pencil-square
            class="btn-inline-text"
            @click="showModalForEdit(data.item)"
            title="Edit"
          ></b-icon-pencil-square>
          <b-iconstack
            class="btn-inline-text ml-1"
            @click="resetPassword(data.item)"
            title="Reset Password"
          >
            <b-icon
              stacked
              icon="arrow-repeat"
              scale="1.25"
              rotate="125"
            ></b-icon>
            <b-icon stacked icon="lock-fill" scale="0.5"></b-icon>
          </b-iconstack>
          <b-icon-trash
            class="btn-inline-text ml-1"
            @click="deleteItem(data.item)"
            title="Delete"
          ></b-icon-trash>
        </template>
      </b-table>
    </div>

    <b-modal
      centered
      ref="form-modal"
      :title="isEdit ? 'Edit User' : 'Add User'"
    >
      <b-form ref="main-form">
        <div v-if="!isEdit" class="form-group row">
          <div class="col-sm-3">
            <label>Account</label>
          </div>
          <div class="col-sm-9">
            <treeselect
              required
              v-model="formItem.accountId"
              :options="accounts"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Email</label>
          </div>
          <div class="col-sm-9">
            <b-input
              type="email"
              class="form-control-sm"
              v-model.trim="formItem.email"
              required
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Roles</label>
          </div>
          <div class="col-sm-9">
            <treeselect
              required
              :multiple="true"
              v-model="formItem.roles"
              :options="roles"
            />
          </div>
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="addOrEditItem"
          >{{ isEdit ? 'Edit' : 'Add' }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      ref="reset-password-modal"
      centered
      ok-only
      hide-header
      button-size="sm"
    >
      Please copy this new password and send it to the user
      <span class="text-info">{{ resetPasswordEmail }}</span
      >.
      <div class="mt-3">
        <b-overlay
          class="d-inline-block border border-primary bg-primary rounded px-3 py-1"
          :show="copiedOverlay"
          variant="success"
          no-center
          rounded
        >
          <template #overlay>
            <span class="d-inline-block text-light copied-password"
              >Copied ...</span
            >
          </template>
          <span
            class="text-truncate new-password text-light"
            @click="copyNewPassword"
          >
            {{ newPassword }}
          </span>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import _ from 'lodash';

export default {
  name: 'Users',
  mixins: [httpclient],
  mounted() {
    this.httpclient.get('api/users/accounts').then((resp) => {
      this.accounts = resp.data.map((account) => {
        return { label: account.text, id: account.value };
      });
      this.formItemDefault.accountId = this.accounts[0].id;
    });
    this.httpclient.get('api/users/roles').then((resp) => {
      this.roles = resp.data.map((role) => {
        return { label: role.text, id: role.value };
      });
    });
    this.getList();
  },
  data() {
    return {
      path: 'users-management',
      accounts: [],
      roles: [],
      formItem: {
        id: '',
        accountId: -1,
        email: '',
        roles: [],
      },
      formItemDefault: {
        id: '',
        accountId: -1,
        email: '',
        roles: [],
      },
      isEdit: false,
      editId: -1,
      fields: [
        {
          key: 'index',
          label: 'No.',
          sortable: false,
        },
        {
          key: 'accountName',
          label: 'Account',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'roles',
          label: 'Roles',
          sortable: true,
        },
        {
          key: 'id',
          label: '',
          sortable: false,
        },
      ],
      items: [],
      perPage: 10000,
      currentPage: 1,
      isBusy: false,

      newPassword: '',
      copiedOverlay: false,
      resetPasswordEmail: '',

      searchText: '',
    };
  },
  computed: {
    sortedItems() {
      let items = [...this.items];
      const searchText = this.searchText.toLowerCase();
      if (searchText) {
        items = items.filter((item) => {
          const email = item.email.toLowerCase();
          return email.includes(searchText);
        });
      }
      return items.sort(
        (a, b) =>
          a.accountName.localeCompare(b.accountName) ||
          a.email.localeCompare(b.email)
      );
    },
    rows() {
      return this.items.length;
    },
  },
  methods: {
    showModal() {
      this.isEdit = false;
      this.editId = -1;
      this.formItem = Object.assign({}, this.formItemDefault);
      this.$refs['form-modal'].show();
    },
    showModalForEdit(data) {
      this.isEdit = true;
      this.editId = data.id;
      this.formItem = _.cloneDeep(_.assign({}, this.formItemDefault, data));
      this.$refs['form-modal'].show();
    },
    hideModal() {
      this.$refs['form-modal'].hide();
    },
    getList() {
      const vm = this;
      this.httpclient.get('api/users').then(function (resp) {
        if (resp.data) {
          vm.items = resp.data;
          vm.$refs['form-modal'].hide();
        }
      });
    },
    deleteItem(data) {
      const vm = this;
      const confirmMessage = 'Remove the User?';
      const id = data.id;

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.httpclient.delete('api/users/' + id).then(function (resp) {
              if (resp.data) {
                vm.items = vm.items.filter((item) => {
                  return id !== item.id;
                });
                vm.$bvModal.msgBoxOk('User had been removed successfully', {
                  okVariant: 'success',
                  centered: true,
                });
              }
            });
          }
        });
    },
    addOrEditItem() {
      const vm = this;
      const mainForm = vm.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      vm.$refs['form-modal'].hide();

      let confirmMessage = vm.isEdit
        ? 'Update the user data?'
        : 'Add the user?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            if (vm.isEdit) {
              vm.editItem();
            } else {
              vm.addItem();
            }
          } else {
            vm.$refs['form-modal'].show();
          }
        });
    },
    editItem() {
      const vm = this;
      vm.httpclient.put('api/users/', vm.formItem).then(function (resp) {
        if (resp.data) {
          const accountName = vm.accounts.find(
            (account) => account.id === vm.formItem.accountId.toString()
          ).label;
          const editedUser = {
            ...vm.formItem,
            ...{ accountName: accountName },
          };
          const items = vm.items.filter((item) => editedUser.id !== item.id);
          vm.items = [...items, ...[editedUser]];
          vm.$refs['form-modal'].hide();
          vm.$bvModal.msgBoxOk('User had been updated successfully', {
            okVariant: 'success',
            centered: true,
          });
        }
      });
    },
    async addItem() {
      const formItem = { ...this.formItem };
      let resp = await this.httpclient.post('api/users', formItem);
      const respData = { ...resp.data };
      const accountName = this.accounts.find(
        (account) => account.id === formItem.accountId.toString()
      ).label;
      const user = {
        ...formItem,
        ...{ id: respData.data, accountName: accountName },
      };
      this.items = [...this.items, ...[user]];
      this.$refs['form-modal'].hide();
      await this.$bvModal.msgBoxOk('User had been added successfully', {
        okVariant: 'success',
        centered: true,
      });
    },
    resetPassword(data) {
      const email = data.email;
      const h = this.$createElement;
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('span', [
          'Reset the password for user ',
          h('span', { class: ['text-info'] }, email),
          '?',
        ]),
      ]);

      //const confirmMessage = `Reset the password for user(${email})?`;

      this.$bvModal
        .msgBoxConfirm(messageVNode, { centered: true, 'button-size': 'sm' })
        .then((confirm) => {
          if (confirm) {
            this.httpclient
              .post('api/users/reset-password', data)
              .then((resp) => {
                if (resp.data) {
                  this.resetPasswordEmail = email;
                  this.newPassword = resp.data.newPassword;
                  this.$refs['reset-password-modal'].show();
                }
              });
          }
        });
    },
    copyNewPassword() {
      navigator.clipboard.writeText(this.newPassword);
      this.copiedOverlay = true;
      setTimeout(() => {
        this.copiedOverlay = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-inline-text {
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.new-password {
  cursor: pointer;
}

.copied-password {
  padding: 0.2em 0 0 2em;
  font-size: 1em;
  font-weight: bold;
}
</style>
